import React from 'react';
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';

export default function NavbarItem(props) {
    return (
        <>
            <Link to={props.homepage ? props.to : `/#${props.to}`} smooth={true} duration={1000} style={{ alignItems: 'center', display: 'flex' }}>
                {
                    props.homepage
                        ?
                        <div className='navbar-item has-text-white'>
                            {props.text}
                        </div>
                        :
                        <RouterLink to={props.homepage ? props.to : `/#${props.to}`} className='navbar-item has-text-white'>
                            {props.text}
                        </RouterLink>
                }
            </Link>
        </>
    )
}