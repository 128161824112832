import { Link } from 'react-scroll';

export default function DropdownTitle(props) {
    return (
        <>
            <Link to={props.to} smooth={true} duration={1000} style={{ alignItems: 'center', display: 'flex' }}>
                <div className="navbar-link" style={{ color: 'white' }}>
                    {props.text}
                </div>
            </Link>
        </>
    )
}