import React, { useState, useEffect } from 'react';
import Scroll from 'react-scroll';

import Navbar from '../components/navbar/Navbar';
import Whatsapp from '../components/Whatsapp'
import Card from '../components/infraestructure/Card';
import CardImage from '../components/infraestructure/CardImage'
import CardTitle from '../components/infraestructure/CardTitle'
import CardContent from '../components/infraestructure/CardContent'
import Footer from '../components/Footer'
import PageLayout from '../components/layout/PageLayout'
import PageTitle from '../components/layout/PageTitle'
import CardContainer from '../components/infraestructure/CardContainer'
import SupportImg from '../assets/img/support.jpg'
import ImplementationImg from '../assets/img/implementation.jpg'
import ServerImg from '../assets/img/server.jpg'

export default function InfraestructurePage() {
    const [componentReady, setComponentReady] = useState(false);

    async function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    useEffect(() => {
        async function loading() {
            // Aquí puedes cargar los archivos que necesitas utilizando la API Fetch o cualquier otra librería que desees.
            //await fetch('http://ejemplo.com/archivos')
            // Cuando los archivos hayan cargado, actualiza el estado para que el componente pueda renderizarlos.
            await sleep(500);
            setComponentReady(true);
        }
        if (!componentReady) {
            Scroll.animateScroll.scrollToTop()
            loading();
        }
    }, []);
    return (
        <>
            <Navbar />
            <PageLayout className={'fade-in-down'}>
                <PageTitle>
                    INFRAESTRUCTURA
                </PageTitle>
                <CardContainer>
                    <Card>
                        <CardImage src={SupportImg} />
                        <CardTitle text={'SOPORTE TECNICO'} />
                        <CardContent>
                            Técnicos en TI resolvemos tus problemas ágilmente y sin interrupciones, para que puedas enfocarte en tu negocio. Ofrecemos asistencia remota, configuración de dispositivos y redes, instalación de apps, puestos de trabajo, cableado y soporte.
                        </CardContent>
                    </Card>
                    <Card>
                        <CardImage src={ImplementationImg} />
                        <CardTitle text={'IMPLEMENTACION'} />
                        <CardContent>
                            Nuestra infraestructura de redes ofrece una solución de cableado estructurado para satisfacer todas las necesidades de conectividad de tu empresa. Con diferentes tipos de cables que se adaptan a cualquier ubicación y entorno, podrás tener una plataforma administrada que une tecnología y servicios corporativos para obtener una solución integral y eficiente.                                        </CardContent>
                    </Card>
                    <Card>
                        <CardImage src={ServerImg} />
                        <CardTitle text={'ADMINISTRACION DE SERVIDORES'} />
                        <CardContent>
                            Ofrecemos instalación y mantenimiento de servidores para centralizar y proteger tu información. Ofrecemos servicios para:

                            <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                                <li>Servidores de Seguridad</li>
                                <li>Servidores de Sistemas y Aplicaciones</li>
                                <li>Servidores de Archivos</li>
                                <li>Servidores de correo electrónico</li>
                                <li>Otros servidores</li>
                            </ul>

                        </CardContent>
                    </Card>
                </CardContainer>
            </PageLayout>
            <Footer />
            <Whatsapp />
        </>
    )
}
