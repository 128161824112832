import React, { useState } from 'react';
import NavbarItem from './NavbarItem'
import LogoSvg from '../../assets/svg/Logo.svg'
import NavbarDropdown from './NavbarDropdown';
import DropdownTitle from './DropdownTitle';
import DropdownList from './DropdownList';
import DropdownOption from './DropdownOption';
import LateralMenu from './LateralMenu';

export default function Navbar({homepage}) {
    const [showMenu, setShowMenu] = useState(false)

    const openRightMenu = () => {
        setShowMenu(true)
    }
    
    return (
        <>
            {
                showMenu
                ?
                <LateralMenu homepage={homepage} setShow={setShowMenu}/>
                :
                <></>
            }
            <header>
                <nav
                    className="navbar pr-6" role="navigation" aria-label="main navigation"
                    style={{
                        padding: '10px',
                        backgroundColor: '#00b9ff30',
                        position: 'fixed',
                        width: '100vw',
                    }}>
                    <div className="navbar-brand">
                        <a style={{ padding: 0 }} className="navbar-item" href="/">
                            <img src={LogoSvg} width="112" style={{height:'50px',maxHeight:'unset'}} />
                            <span className='has-text-white'>
                                Soluciones Tecnologicas
                            </span>
                        </a>

                        <button onClick={openRightMenu} role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </button>
                    </div>

                    <div id="navbarBasicExample" className="navbar-menu mr-6">
                        <div className="navbar-end">
                            <NavbarItem to={'inicio'} text={'Inicio'} homepage={homepage}/>
                            <NavbarItem to={'nosotros'} text={'Nosotros'} homepage={homepage}/>
                            <NavbarDropdown>
                                <DropdownTitle to={'servicios'} text={'Servicios'} />
                                <DropdownList>
                                    <DropdownOption to={'infraestructura'} text={'INFRAESTRUCTURA Y TECNOLOGIA'}/>
                                    <DropdownOption to={'videovigilancia'} text={'VIDEOVIGILANCIA'}/>
                                    <DropdownOption to={'desarrollo'} text={'DESARROLLO DE SISTEMAS'}/>
                                    <DropdownOption to={'consultoria'} text={'CONSULTORÍA IT'}/>
                                </DropdownList>
                            </NavbarDropdown>
                            {
                                //<NavbarItem to={'contacto'} text={'Contacto'} homepage={homepage}/>
                            }
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}