import { address, infoPhone, infoEmail, companyName } from '../config'

export default function Footer() {
  return (
    <footer className="footer has-text-white" style={{ backgroundColor: 'transparent' }}>
      <div className="container">
        <div className="columns">
          <div className="column">
            <h4 className="title is-4 has-text-white">Nuestra empresa</h4>
            <p>Somos una empresa de tecnología que se dedica a ofrecer soluciones innovadoras para mejorar la vida de las personas.</p>
          </div>
          <div className="column">
            <h4 className="title is-4 has-text-white">Contacto</h4>
            <ul>
              <li>
                <iframe
                  style={{ width: '350px', height: '200px' }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3290.593310359495!2d-58.80060552506694!3d-34.43708407301453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bc9ec27adbf2f1%3A0x7403ed154aa34d2e!2sLos%20Crisantemos%20265%2C%20B1669%20Del%20Viso%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1682077977250!5m2!1ses-419!2sar"
                  width="600"
                  height="450"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
              </li>
              <li><i class="fas fa-map-marker-smile mr-4"></i>Dirección: {address}</li>
              <li><i class="fas fa-phone mr-2"></i> Teléfono: {infoPhone}</li>
              <li><i class="fas fa-envelope mr-2"></i> Correo electrónico: {infoEmail}</li>
            </ul>
          </div>
          <div className="column">
            <h4 className="title is-4 has-text-white">Síguenos</h4>
            <ul className="is-flex is-justify-content-space-between redes" >
              <li><a href="#"><i style={{ fontSize: '50px' }} class="fab fa-facebook-square has-text-white"></i></a></li>
              <li><a href="#"><i style={{ fontSize: '50px' }} class="fab fa-linkedin has-text-white"></i></a></li>
              <li><a href="#"><i style={{ fontSize: '50px' }} class="fab fa-instagram-square has-text-white"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bottom-bar">
        <div className="container">
          <div className="columns">
            <div className="column">
              <p className="has-text-centered">
                <a href="https://www.freepik.es/vector-gratis/negocio-marketing-buscadores-servicio-redaccion-gestion-contenidos_12085329.htm">Imagen de vectorjuice</a> en Freepik
              </p>
              <p className="has-text-centered">&copy; 2023 {companyName}. Todos los derechos reservados.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
