export default function Card({children}) {
    return (
        <div className='column'>
            <div>
                <div
                    style={{ minHeight: '70vh' }}
                    className='is-flex is-flex-direction-column is-justify-content-space-between is-align-items-center has-background-white padding6 m-5'>
                    {children}
                    </div>
            </div>
        </div>
    )
}