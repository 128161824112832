import React, { useState, useEffect } from 'react';
import Scroll from 'react-scroll';

import Navbar from '../components/navbar/Navbar';
import Whatsapp from '../components/Whatsapp'
import LongCard from '../components/infraestructure/LongCard';
import LongCardImage from '../components/infraestructure/LongCardImage'
import CardTitle from '../components/infraestructure/CardTitle'
import CardContent from '../components/infraestructure/CardContent'
import Footer from '../components/Footer'
import PageLayout from '../components/layout/PageLayout'
import LongCardContainer from '../components/infraestructure/LongCardContainer'
import CourseImg from '../assets/img/course.jpg'
import ConsultImg from '../assets/img/consultoria.jpg'

export default function ConsultoriaPage() {
    const [componentReady, setComponentReady] = useState(false);

    async function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    useEffect(() => {
        async function loading() {
            // Aquí puedes cargar los archivos que necesitas utilizando la API Fetch o cualquier otra librería que desees.
            //await fetch('http://ejemplo.com/archivos')
            // Cuando los archivos hayan cargado, actualiza el estado para que el componente pueda renderizarlos.
            await sleep(500);
            setComponentReady(true);
        }
        if (!componentReady) {
            Scroll.animateScroll.scrollToTop()
            loading();
        }
    }, []);
    return (
        <>
            <Navbar />
            <PageLayout className={'fade-in-down'}>
                <h1 className='has-text-centered mt-2 fontsize20m' style={{ fontSize: '45px', color: 'white' }}>
                    CONSULTORIA
                </h1>
                <LongCardContainer>
                    <LongCard>
                        <LongCardImage src={CourseImg} />
                        <div style={{ height: '100%' }} className='is-flex is-flex-direction-column is-justify-content-flex-start align-items-flex-start p-6'>
                            <CardTitle text={'CAPACITACIONES CORPORATIVAS'} />
                            <CardContent style={{ fontSize: '1.2rem' }}>
                                En SOLUTION IT, nos apasiona la tecnología y creemos en el poder de la capacitación para ayudar a las empresas a crecer y mejorar su desempeño. Es por eso que ofrecemos una variedad de capacitaciones sobre tecnología, buenas prácticas, seguridad y respaldo de la información, para que puedas elegir la que mejor se adapte a las necesidades de tu empresa.

                                Nuestros cursos están diseñados por expertos en la materia, que cuentan con una amplia experiencia y conocimientos actualizados sobre las últimas tendencias y herramientas tecnológicas. Te brindamos opciones de capacitación presencial o en línea, y nos aseguramos de que los contenidos sean prácticos, fáciles de entender y aplicar.

                                En nuestras capacitaciones, aprenderás sobre tecnologías emergentes, mejores prácticas para aumentar la eficiencia de tus operaciones, cómo proteger la información sensible de tu empresa, así como estrategias para respaldar tus datos y recuperarlos en caso de pérdida. Además, nuestros cursos son altamente personalizables, por lo que podemos adaptarlos a las necesidades específicas de tu empresa y tu equipo.
                            </CardContent>
                        </div>
                    </LongCard>
                    <LongCard>
                        <div style={{ height: '100%' }} className='is-flex is-flex-direction-column is-justify-content-flex-start align-items-flex-start p-6'>
                            <CardTitle text={'CONSULTORIA INFORMATICA'} />
                            <CardContent style={{ fontSize: '1.2rem' }}>
                                En SOLUTION IT, estamos comprometidos con el éxito de tu empresa. Por eso, ofrecemos servicios de asesoramiento y consultoría en tecnología informática para ayudarte a mejorar el crecimiento y desarrollo de tu sector informático.

                                Contamos con un equipo de expertos en tecnología que tienen una amplia experiencia y conocimientos actualizados en las últimas tendencias y soluciones informáticas. Analizamos detenidamente tu situación empresarial y te brindamos soluciones personalizadas que se adapten a tus necesidades específicas.

                                Nuestros servicios de consultoría informática incluyen la evaluación y optimización de sistemas, la implementación de tecnología de vanguardia para mejorar la eficiencia, la seguridad y el respaldo de la información, la gestión de proyectos informáticos y la capacitación de tus empleados.
                            </CardContent>
                        </div>
                        <LongCardImage src={ConsultImg} />
                    </LongCard>
                </LongCardContainer>

                <Footer />
            </PageLayout>
            <Whatsapp />
        </>
    )
}
