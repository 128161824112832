import React, { useState, useEffect } from 'react';
import Scroll from 'react-scroll';
import Navbar from '../components/navbar/Navbar';
import Section from '../components/Section'
import Whatsapp from '../components/Whatsapp'
import CLogo from '../assets/svg/CLogo.svg'
import AboutImg from '../assets/img/about.jpg'
import InfraImg from '../assets/img/infra.jpg'
import DesignImg from '../assets/img/design.jpg'
import DevelopmentImg from '../assets/img/development.jpg'
import ConsultImg from '../assets/img/consult.jpg'
import ServiceItem from '../components/navbar/ServiceItem';
import ContactForm from '../components/forms/contact/ContactForm';
import Footer from '../components/Footer'
import GoToTop from '../components/GoToTop';

export default function HomePage() {
    const [componentReady, setComponentReady] = useState(false);
    const [showGoToTop, setShowGoToTop] = useState(false);

    async function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const handleScroll = () => {
        if (window.pageYOffset > 100) {
            setShowGoToTop(true);
        } else {
            setShowGoToTop(false);
        }
    };

    const handleClick = () => {
        Scroll.animateScroll.scrollToTop()
    };

    useEffect(() => {
        async function loading() {
            // Aquí puedes cargar los archivos que necesitas utilizando la API Fetch o cualquier otra librería que desees.
            //await fetch('http://ejemplo.com/archivos')
            // Cuando los archivos hayan cargado, actualiza el estado para que el componente pueda renderizarlos.
            await sleep(500);
            setComponentReady(true);
        }
        if (!componentReady) {
            Scroll.animateScroll.scrollToTop()
            loading();
        }
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <Navbar homepage={true} />
            {
                componentReady
                    ?
                    <>
                        <div style={{ position: 'relative', zIndex: 1, width: '100%' }}>
                            <Section animated={true} id={'inicio'} color={'#0000001f'} classname={"fade-in-left has-text-centered is-flex is-justify-content-center is-flex-direction-column"}>
                                <figure>
                                    <img src={CLogo} />
                                </figure>
                                <h2 className="subtitle" style={{ color: 'white', fontSize: '2rem' }}>
                                    Transforma tu negocio con soluciones de IT innovadoras
                                </h2>
                            </Section>
                            <Section color={'#007eff33'} id={'nosotros'} classname={"is-justify-content-center is-align-items-center is-flex is-justify-content-space-around is-flex-direction-column"}>
                                <h1 className="title title-section-home" style={{ color: 'white' }}>Nosotros</h1>
                                <div className='is-flex section-content-img-text-home'>
                                    <figure className='is-flex is-justify-content-center is-align-items-center margin-button-mobile-20'>
                                        <img style={{ width: '90%' }} src={AboutImg} alt="" />
                                    </figure>
                                    <h2 className="subtitle has-text-white is-flex is-flex-direction-column is-justify-content-space-between"
                                        style={{ fontSize: '1rem' }}>
                                        <span className='mb-4'>
                                            En SOLUTION IT, estamos comprometidos con proporcionar soluciones tecnológicas confiables y de alta calidad a nuestros clientes. Con años de experiencia en el sector, hemos perfeccionado nuestros procesos para ofrecer un servicio eficiente, dedicado y personalizado que cumpla con todas las necesidades de nuestros clientes.
                                        </span>
                                        <span className='mb-4'>
                                            Nuestro equipo está formado por profesionales altamente capacitados y comprometidos con la excelencia. Además, utilizamos tecnología de vanguardia y diseños personalizados para garantizar que cada solución que entregamos esté adaptada específicamente a las necesidades de cada cliente.
                                        </span>
                                        <span className='mb-4'>
                                            Entendemos que el tiempo es crucial en cualquier proyecto tecnológico. Es por eso que nos aseguramos de establecer planes de gestión efectivos que prioricen la eficiencia y la eficacia en cada paso del proceso, para poder cumplir con los requerimientos de nuestros clientes en el menor tiempo posible.
                                        </span>
                                        <span className='mb-4'>
                                            En SOLUTION IT, nuestro objetivo es ayudar a nuestros clientes a avanzar hacia sus objetivos empresariales con la tranquilidad de que sus soluciones tecnológicas están en manos confiables y experimentadas. Si está buscando una empresa de soluciones tecnológicas que ofrezca un servicio excepcional, ¡SOLUTION IT es la elección perfecta para usted!
                                        </span>
                                    </h2>
                                </div>

                            </Section>
                            <Section color={'#1d677c59'} id={'servicios'} classname={"is-align-items-center is-flex is-justify-content-center is-flex-direction-column"}>
                                <h1 className="title mb-6 title-section-home" style={{ color: 'white' }}>Servicios</h1>
                                <div className="columns mt-5" style={{ width: '100%', height: '50%' }}>
                                    <ServiceItem to={'infraestructura'} text={'INFRAESTRUCTURA Y TECNOLOGIA'} img={InfraImg} />
                                    <ServiceItem to={'videovigilancia'} text={'VIDEOVIGILANCIA'} img={DesignImg} />
                                    <ServiceItem to={'desarrollo'} text={'DESARROLLO DE SISTEMAS'} img={DevelopmentImg} />
                                    <ServiceItem to={'consultoria'} text={'CONSULTORÍA IT'} img={ConsultImg} />
                                </div>
                            </Section>
                            {
                                /*
                                    <Section color={'#3f00ff1c'} id={'contacto'} classname={"title-section-home is-align-items-center is-flex is-justify-content-center is-flex-direction-column"}>
                                        <h1 className="title mt-6 mb-6" style={{ color: 'white' }}>Contacto</h1>
                                        <ContactForm />
                                    </Section>
                                */
                            }
                        </div>
                    </>
                    :
                    <>
                        <div style={{ position: 'relative', zIndex: 1, width: '100%' }}>
                            <Section animated={true} id={'inicio'} color={'#0000001f'} classname={"fade-in-left has-text-centered is-flex is-justify-content-center is-flex-direction-column"}>

                            </Section>
                        </div>
                    </>
            }
            <Footer />
            <Whatsapp />
            {showGoToTop && (
                <GoToTop handleClick={handleClick} />
            )}
        </>
    )
}
