import React from 'react';
import { Routes, Route } from "react-router-dom";
import ParticlesBg from './components/ParticlesBg';
import HomePage from './views/HomePage'
import InfraestructurePage from './views/InfraestructurePage'
import DevelopmentPage from './views/DevelopmentPage'
import ConsultoriaPage from './views/ConsultoriaPage'
import VideovigilanciaPage from './views/VideovigilanciaPage'
import "./App.css"

export default function App() {
  return (
    <>
      <ParticlesBg />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/inicio" element={<HomePage />} />
            <Route path="/infraestructura" element={<InfraestructurePage />} />
            <Route path="/desarrollo" element={<DevelopmentPage />} />
            <Route path="/videovigilancia" element={<VideovigilanciaPage />} />
            <Route path="/consultoria" element={<ConsultoriaPage />} />
          </Routes>
    </>
  )
}