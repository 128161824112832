import { Link as RouterLink } from 'react-router-dom';

export default function DropdownOption({ text, to }) {
    return (
        <>
            <RouterLink to={`/${to}`} className="navbar-item has-text-white">
                {text}
            </RouterLink>
        </>
    )
}