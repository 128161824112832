import { Link as RouterLink } from 'react-router-dom';
export default function ServiceItem(props) {
    return (
        <>
            <RouterLink to={props.to} className="column service-item">
                <img src={props.img} alt={props.text} />
                <p style={{ fontSize: '1.2rem' }} className="has-text-white has-text-centered m-auto">
                    {props.text}
                </p>
            </RouterLink>
        </>
    )
}