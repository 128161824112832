import { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";

export default function Section({ children, id, color, classname, animated }) {

    if (animated) {
        return (
            <div
                id={id}
                className={`section top-section-home is-fluid m-auto ${classname} `}
                style={{ backgroundColor: '#00000047', backgroundColor: color }}>
                <div id="content-top-home">
                    {children}
                </div>
            </div>
        )
    }

    return (
        <>
            <div
                id={id}
                className={`section section-home is-fluid m-auto ${classname} `}
                style={{ backgroundColor: color }}>
                {children}
            </div>
        </>
    )
};