import React, { useState, useEffect } from 'react';
import Scroll from 'react-scroll';
import Navbar from '../components/navbar/Navbar';
import Whatsapp from '../components/Whatsapp'
import Card from '../components/infraestructure/Card';
import CardImage from '../components/infraestructure/CardImage'
import CardTitle from '../components/infraestructure/CardTitle'
import CardContent from '../components/infraestructure/CardContent'
import Footer from '../components/Footer'
import PageLayout from '../components/layout/PageLayout'
import PageTitle from '../components/layout/PageTitle'
import CardContainer from '../components/infraestructure/CardContainer'
import ImplementationImg from '../assets/img/implementation.jpg'
import Camera1 from '../assets/img/camera1.jpg'
import Camera2 from '../assets/img/camera2.jpg'

export default function VideovigilanciaPage() {
    const [componentReady, setComponentReady] = useState(false);

    async function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    useEffect(() => {
        async function loading() {
            // Aquí puedes cargar los archivos que necesitas utilizando la API Fetch o cualquier otra librería que desees.
            //await fetch('http://ejemplo.com/archivos')
            // Cuando los archivos hayan cargado, actualiza el estado para que el componente pueda renderizarlos.
            await sleep(500);
            setComponentReady(true);
        }
        if (!componentReady) {
            Scroll.animateScroll.scrollToTop()
            loading();
        }
    }, []);
    return (
        <>
            <Navbar />
            <PageLayout className={'fade-in-down'}>
                <PageTitle>
                    VIDEOVIGILANCIA
                </PageTitle>
                <CardContainer>
                    <Card>
                        <CardImage src={ImplementationImg} />
                        <CardTitle text={'IMPLEMENTACION'} />
                        <CardContent>
                            Creamos soluciones tecnológicas a medida, diseñadas específicamente para tu proyecto y utilizando las mejores herramientas del mercado.
                        </CardContent>
                    </Card>
                    <Card>
                        <CardImage src={Camera1} />
                        <CardTitle text={'Modelo BULLET'} />
                        <CardContent>
                            Las cámaras bullet son resistentes y versátiles en su instalación, montables en pared o techo con soportes ajustables para amplios ángulos de visión y dirección. Perfectas para vigilar espacios amplios y exteriores, como patios, estacionamientos y áreas de carga. También útiles para supervisar puertas de entrada y populares para seguridad de negocios y hogares.
                        </CardContent>
                    </Card>
                    <Card>
                        <CardImage src={Camera2} />
                        <CardTitle text={'Modelo DOMO'} />
                        <CardContent>
                            Las cámaras tipo domo tienen un diseño elegante y discreto que las hace ideales para instalaciones en interiores, como oficinas, tiendas y edificios públicos. Además, su forma de domo proporciona un amplio ángulo de visión, lo que las hace ideales para vigilar grandes áreas como salones de exhibición y tiendas minoristas. También son resistentes y duraderas, lo que las hace adecuadas para su uso en entornos de alta actividad.
                        </CardContent>
                    </Card>
                </CardContainer>
            </PageLayout>
            <Footer />
            <Whatsapp />
        </>
    )
}
