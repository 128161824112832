export default function LongCard({children}) {
    return (
        <div className='column p-0'>
            <div className="is-flex is-justify-content-center">
                <div
                    style={{ maxWidth: '90%'}}
                    className='is-flex is-justify-content-center is-align-items-center padding6 m-0 mt-5 has-background-white'>
                    {children}
                    </div>
            </div>
        </div>
    )
}