import React, { useState, useEffect } from 'react';
import Scroll from 'react-scroll';

import Navbar from '../components/navbar/Navbar';
import Whatsapp from '../components/Whatsapp'
import Card from '../components/infraestructure/Card';
import CardImage from '../components/infraestructure/CardImage'
import CardTitle from '../components/infraestructure/CardTitle'
import CardContent from '../components/infraestructure/CardContent'
import Footer from '../components/Footer'
import PageLayout from '../components/layout/PageLayout'
import PageTitle from '../components/layout/PageTitle'
import CardContainer from '../components/infraestructure/CardContainer'
import DevImg from '../assets/img/development.jpg'
import TeamDev from '../assets/img/teamdev.jpg'
import AppImg from '../assets/img/apps.jpg'

export default function DevelopmentPage() {
    const [componentReady, setComponentReady] = useState(false);

    async function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    useEffect(() => {
        async function loading() {
            // Aquí puedes cargar los archivos que necesitas utilizando la API Fetch o cualquier otra librería que desees.
            //await fetch('http://ejemplo.com/archivos')
            // Cuando los archivos hayan cargado, actualiza el estado para que el componente pueda renderizarlos.
            await sleep(500);
            setComponentReady(true);
        }
        if (!componentReady) {
            Scroll.animateScroll.scrollToTop()
            loading();
        }
    }, []);
    return (
        <>
            <Navbar />
            <PageLayout className={'fade-in-down'}>
                <PageTitle>
                    DESARROLLOS DE SISTEMAS
                </PageTitle>
                <CardContainer>
                    <Card>
                        <CardImage src={TeamDev} />
                        <CardTitle text={'DESAROLLO A MEDIDA'} />
                        <CardContent>
                            Creamos soluciones tecnológicas a medida, diseñadas específicamente para tu proyecto y utilizando las mejores herramientas del mercado.
                        </CardContent>
                    </Card>
                    <Card>
                        <CardImage src={DevImg} />
                        <CardTitle text={'SISTEMAS WEB'} />
                        <CardContent>
                            Ofrecemos soluciones tecnológicas a medida, desarrolladas por especialistas en programación de plataformas y aplicaciones web que se ajustan perfectamente a las necesidades de tu proyecto.                        </CardContent>
                    </Card>
                    <Card>
                        <CardImage src={AppImg} />
                        <CardTitle text={'MOVILES APPS'} />
                        <CardContent>
                            Diseñamos y programamos herramientas para dispositivos móviles que están al alcance de cualquier persona, con el objetivo de mejorar tu productividad y facilitar tus actividades cotidianas.                        </CardContent>
                    </Card>
                </CardContainer>
            </PageLayout>
            <Footer />
            <Whatsapp />
        </>
    )
}
