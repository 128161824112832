import { Link } from 'react-scroll';

export default function LateralMenuItem(props) {
    return (
        <>
            <Link
                onClick={()=>{props.setShow(false)}}
                to={props.homepage ? props.to : `/#${props.to}`}
                smooth={true}
                duration={1000}
                className='mb-3'>
                <i style={{ fontSize: '30px' }} class={`fas fa-${props.icon} has-text-white`}></i>
                <span style={{ fontSize: '25px' }} className='has-text-white ml-4'>
                    {props.text}
                </span>
                <div style={{ width: '100%', height: '1px', backgroundColor: 'white', marginBottom: '10px' }}></div>
            </Link>
        </>
    )
}