import LateralMenuItem from "./LateralMenuItem";

export default function LateralMenu(props) {
    return (
        <div className='lateralMenu fade-in-right' >
            <div
                onClick={()=>{props.setShow(false)}}
                style={{width:'100%', height:'100%', position:'absolute',left:0,top:0}}
            ></div>
            <div className='lateralMenuChild p-3 pt-6'>
                <LateralMenuItem setShow={props.setShow} homepage={props.homepage} to={'inicio'} text={'Inicio'} icon={'home'} />
                <LateralMenuItem setShow={props.setShow} homepage={props.homepage} to={'nosotros'} text={'Nosotros'} icon={'home'} />
                <LateralMenuItem setShow={props.setShow} homepage={props.homepage} to={'servicios'} text={'Servicios'} icon={'home'} />
                <LateralMenuItem setShow={props.setShow} homepage={props.homepage} to={'contacto'} text={'Contacto'} icon={'home'} />
            </div>
        </div>
    )
}